<template>

	<div class="theme-cyan authentication sidebar-collapse">

		<div class="page-header">
			<div class="page-header-image" style="background-image:url(assets/images/login.jpg);"></div>
			<div class="container">
				<div class="col-md-12 content-center">
					<div class="card-plain">
						<div class="form" @keyup.enter="login">
							<div class="header">
								<div class="logo-container"><img src="assets/images/logo-white.png" alt=""></div>
								<h4>大冶市招商局信息填报系统</h4>
								<h6>Standard 1.03-231219</h6>
							</div>
							<div class="content">
								<div class="input-group input-lg">
									<input type="text" v-model="username" class="form-control" placeholder="用户名 / 手机号登录">
									<span class="input-group-addon">
										<i class="zmdi zmdi-account-circle"></i>
									</span>
								</div>
								<div class="input-group input-lg">
									<input :type="password_type" v-model="password" @focus="inputPassword"
										placeholder="密码" class="form-control" />
									<span class="input-group-addon">
										<i class="zmdi zmdi-lock"></i>
									</span>
								</div>
							</div>
							<div class="footer text-center">
								<a href="javascript:void(0)"
									class="btn l-cyan btn-round btn-lg btn-block waves-effect waves-light"
									@click="login">登 陆</a>
								<h6 class="m-t-20"><a href="javascript:void(0)" class="link">Daye City Merchants Bureau information filling system</a></h6>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

	</div>

</template>

<script>
	import loginController from '@/controller/LoginController.js'
	export default {
		created() {

		},
		mounted() {

		},
		data() {
			return {
				password_type: 'text',
				username: '',
				password: '',
			}
		},
		methods: {
			login() {
				
				if(!this.username){
					this.$message.error('请输入用户名')
					return;
				}
				
				if(!this.password){
					this.$message.error('请输入密码')
					return;
				}

				loginController.validate({
					username: this.username,
					password: this.base64Encode(this.password)
				}).then(res => {
					
					 sessionStorage.setItem('token',res.data.token)
					  sessionStorage.setItem('user',JSON.stringify(res.data.user))
					  sessionStorage.setItem('role',JSON.stringify(res.data.role))
					  sessionStorage.setItem('resouces',JSON.stringify(res.data.resouces))
					  this.go('/',null,false)


					// if(res.data.role.name === '招商局管理员'){
					// 	sessionStorage.setItem('token',res.data.token)
					// 	sessionStorage.setItem('user',JSON.stringify(res.data.user))
					// 	sessionStorage.setItem('role',JSON.stringify(res.data.role))
					// 	sessionStorage.setItem('resouces',JSON.stringify(res.data.resouces))
					// 	this.go('/',null,false)
					// }else{
					// 	this.$alert("目前进入年终数据统计阶段，系统暂停使用","通知",{
					// 		iconClass:'el-icon-sold-out'
					// 	})
					// }
				})

			},
			inputPassword() {
				this.password_type = 'password'
			}

		},

	}
</script>

<style>


</style>
